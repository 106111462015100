import React, { useEffect, useState } from "react";
import ProductCard from "../components/productCard";
import { ItemFiltredAPI } from "../api/ItemFiltredAPI";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const ProductList = () => {
  const [ProductsFetchedData, setProductsFetchedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [parameters, setParameter] = useState({
    categoryId: [],
    typeId: [],
    collectionId: [],
    themeId: [],
    sizeId: [],
    colorId: [],
    discountId: [],
    search: null,
    page: 0,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    const type_ids = searchParams.getAll("type_ids[]");
    const theme_ids = searchParams.getAll("theme_ids[]");
    const category_ids = searchParams.getAll("category_ids[]");
    const size_ids = searchParams.getAll("size_ids[]");
    const color_ids = searchParams.getAll("color_ids[]");
    const discount_ids = searchParams.getAll("discount_ids[]");
    const page = getPageFromUrl();
    setParameter((params) => ({
      ...params,
      typeId: type_ids,
      themeId: theme_ids,
      categoryId: category_ids,
      sizeId: size_ids,
      colorId: color_ids,
      discountId: discount_ids,
      page: page,
    }));
  }, [location]);
  const getPageFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return parseInt(params.get("page")) || 1;
  };
  const changePage = (newPage) => {
    const params = new URLSearchParams(location.search);
    params.set("page", newPage);
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const nextPage = () => {
    if (pagination.current_page < pagination.last_page) {
      setLoading(true);
      changePage(parameters.page + 1);
    }
  };

  const previousPage = () => {
    if (pagination.current_page > 1) {
      setLoading(true);
      changePage(parameters.page - 1);
    }
  };

  const firstPage = () => {
    setLoading(true);
    changePage(1);
  };

  const lastPage = () => {
    setLoading(true);
    changePage(pagination.last_page);
  };
  const apiCall = () => {
    try {
      ItemFiltredAPI.getAll(parameters.page, parameters).then((products) => {
        setProductsFetchedData(products.data);
        setPagination({
          current_page: products.current_page,
          total: products.total,
          last_page: products.last_page,
          per_page: products.per_page,
        });
        setLoading(false);
        scrollToTop();
      });
    } catch (error) {}
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    if (
      parameters.categoryId.length > 0 ||
      parameters.typeId.length > 0 ||
      parameters.collectionId.length > 0 ||
      parameters.themeId.length > 0 ||
      parameters.discountId.length > 0 ||
      parameters.colorId.length > 0 ||
      parameters.sizeId.length > 0 ||
      parameters.page > 0
    ) {
      setLoading(true);
      apiCall();
    }
  }, [parameters]);

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-2 gap-4 p-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 lg:gap-6">
            {ProductsFetchedData.length > 0 ? (
              ProductsFetchedData.map((product, index) => (
                <ProductCard key={index} product={product} />
              ))
            ) : (
              <div className="flex col-span-3 items-center justify-center min-h-screen text-center">
                <span className="text-xl font-semibold">
                  NOTHING MATCHES YOUR SEARCH
                </span>
              </div>
            )}
          </div>
          <div>
            {ProductsFetchedData.length > 0 ? (
              <nav
                aria-label="Pagination"
                className="flex items-center text-gray-600 justify-center my-10"
              >
                <div
                  className="p-2 mr-4 rounded hover:bg-gray-100 cursor-pointer"
                  onClick={() => previousPage()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </div>
                {pagination.current_page !== 1 ? (
                  <div
                    className="px-4 py-2 rounded hover:bg-gray-100 cursor-pointer"
                    onClick={() => firstPage()}
                  >
                    1
                  </div>
                ) : null}

                <div className="px-4 py-2 rounded bg-gray-200 text-gray-900 font-medium hover:bg-gray-100">
                  {pagination.current_page}
                </div>

                {pagination.current_page !== pagination.last_page ? (
                  <>
                    <div className="px-4 py-2 rounded hover:bg-gray-100">
                      ...
                    </div>
                    <div
                      className="px-4 py-2 rounded hover:bg-gray-100 cursor-pointer"
                      onClick={() => lastPage()}
                    >
                      {pagination.last_page}
                    </div>
                  </>
                ) : null}

                <div
                  className="p-2 ml-4 rounded hover:bg-gray-100 cursor-pointer"
                  onClick={() => nextPage()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </nav>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default ProductList;
