import { useState } from "react";
import ProductCard from "../components/productCard";

// OrderCard Component
function OrderCard({ order }) {
  return (
    <div className="bg-white shadow-md rounded-lg p-4 mb-4">
      <h3 className="text-lg font-semibold text-gray-900">
        Order ID: {order.id}
      </h3>
      <p className="text-sm text-gray-500">Status: {order.status}</p>
      <p className="text-sm text-gray-500">Total Amount: ${order.amount}</p>

      <div className="mt-4">
        <h4 className="font-bold text-gray-700">Products:</h4>
        <ul className="list-disc list-inside">
          {order.products.map((product, index) => (
            <li key={index} className="text-gray-600">
              {product}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

// ProfileTab Component
function ProfileTab() {
  const [editMode, setEditMode] = useState(false);
  const [addresses, setAddresses] = useState(["Address 1"]);
  const [profile, setProfile] = useState({
    firstName: "John",
    lastName: "Doe",
    email: "john@example.com",
    phone: "123-456-7890",
  });

  const toggleEditMode = () => setEditMode(!editMode);

  const addAddress = () => {
    if (addresses.length < 3) {
      setAddresses([...addresses, `Address ${addresses.length + 1}`]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold mb-6 text-center text-gray-900">
        My Profile
      </h2>

      {!editMode ? (
        <div>
          <p className="text-sm text-gray-700">
            <strong>First Name:</strong> {profile.firstName}
          </p>
          <p className="text-sm text-gray-700">
            <strong>Last Name:</strong> {profile.lastName}
          </p>
          <p className="text-sm text-gray-700">
            <strong>Email:</strong> {profile.email}
          </p>
          <p className="text-sm text-gray-700">
            <strong>Phone:</strong> {profile.phone}
          </p>

          <div className="mt-4">
            <h4 className="font-bold text-gray-700">Addresses:</h4>
            <ul className="list-disc list-inside">
              {addresses.map((address, index) => (
                <li key={index} className="text-gray-600">
                  {address}
                </li>
              ))}
            </ul>
          </div>
          <button
            className="mt-4 px-4 py-2 bg-black text-white rounded-md"
            onClick={toggleEditMode}
          >
            Edit
          </button>
        </div>
      ) : (
        <div>
          <input
            type="text"
            name="firstName"
            value={profile.firstName}
            onChange={handleInputChange}
            className="block w-full rounded-md border-gray-300 mb-2"
          />
          <input
            type="text"
            name="lastName"
            value={profile.lastName}
            onChange={handleInputChange}
            className="block w-full rounded-md border-gray-300 mb-2"
          />
          <input
            type="email"
            name="email"
            value={profile.email}
            onChange={handleInputChange}
            className="block w-full rounded-md border-gray-300 mb-2"
          />
          <input
            type="tel"
            name="phone"
            value={profile.phone}
            onChange={handleInputChange}
            className="block w-full rounded-md border-gray-300 mb-2"
          />

          <div className="mt-4">
            <h4 className="font-bold text-gray-700">Addresses:</h4>
            {addresses.map((address, index) => (
              <input
                key={index}
                type="text"
                value={address}
                onChange={(e) => {
                  const newAddresses = [...addresses];
                  newAddresses[index] = e.target.value;
                  setAddresses(newAddresses);
                }}
                className="block w-full rounded-md border-gray-300 mb-2"
              />
            ))}
            {addresses.length < 3 && (
              <button
                className="mt-2 px-4 py-2 bg-black text-white rounded-md"
                onClick={addAddress}
              >
                + Add Address
              </button>
            )}
          </div>

          <button
            className="mt-4 px-4 py-2 bg-black text-white rounded-md"
            onClick={toggleEditMode}
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
}

// MyOrdersTab Component
function MyOrdersTab() {
  const orders = [
    {
      id: "12345",
      status: "Shipped",
      amount: 150.0,
      products: ["Product 1", "Product 2"],
    },
    {
      id: "67890",
      status: "Processing",
      amount: 220.0,
      products: ["Product 3", "Product 4"],
    },
  ];

  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold mb-6 text-center text-gray-900">
        My Orders
      </h2>
      {orders.map((order) => (
        <OrderCard key={order.id} order={order} />
      ))}
    </div>
  );
}

// OrderHistoryTab Component
function OrderHistoryTab() {
  const orderHistory = [
    {
      id: "34567",
      status: "Delivered",
      amount: 99.0,
      products: ["Product 5", "Product 6"],
    },
    {
      id: "89012",
      status: "Delivered",
      amount: 300.0,
      products: ["Product 7", "Product 8"],
    },
  ];

  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold mb-6 text-center text-gray-900">
        Order History
      </h2>
      {orderHistory.map((order) => (
        <OrderCard key={order.id} order={order} />
      ))}
    </div>
  );
}

// MyFavoritesTab Component
function MyFavoritesTab({ favoriteProducts }) {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold mb-6 text-center text-gray-900">
        Favorite Products
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {favoriteProducts.map((product) => (
          <ProductCard
            key={product.id}
            image={product.image}
            colors={product.colors}
            name={product.name}
            price={product.price}
          />
        ))}
      </div>
    </div>
  );
}

// Main AccountTabs Component
function AccountTabs() {
  const [activeTab, setActiveTab] = useState("profile");
  const favoriteProducts = [
    {
      id: "prod1",
      image:
        "https://cdn-media.originalmarines.com/pub/media/catalog/product/cache/18824f6b507c2699e236df7b0d7ebc28/D/E/DEA3790F_17-0115TCX_01_D_8.webp",
      colors: ["#D1A3FF"],
      name: "Striped Sweater",
      price: "€15.99",
    },
  ];

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="flex justify-center space-x-4 mb-6">
        <button
          onClick={() => setActiveTab("profile")}
          className={`px-4 py-2 ${
            activeTab === "profile" ? "bg-black text-white" : "text-gray-600"
          }`}
        >
          My Profile
        </button>
        <button
          onClick={() => setActiveTab("orders")}
          className={`px-4 py-2 ${
            activeTab === "orders" ? "bg-black text-white" : "text-gray-600"
          }`}
        >
          My Orders
        </button>
        <button
          onClick={() => setActiveTab("history")}
          className={`px-4 py-2 ${
            activeTab === "history" ? "bg-black text-white" : "text-gray-600"
          }`}
        >
          Order History
        </button>
        <button
          onClick={() => setActiveTab("favorites")}
          className={`px-4 py-2 ${
            activeTab === "favorites" ? "bg-black text-white" : "text-gray-600"
          }`}
        >
          Favorite Products
        </button>
      </div>

      {activeTab === "profile" && <ProfileTab />}
      {activeTab === "orders" && <MyOrdersTab />}
      {activeTab === "history" && <OrderHistoryTab />}
      {activeTab === "favorites" && (
        <MyFavoritesTab favoriteProducts={favoriteProducts} />
      )}
    </div>
  );
}

export default AccountTabs;
