import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import Login from "../pages/signin";
import Home from "../pages/home";
import ProductListingPage from "../pages/ProductListingPage";
import SignUp from "../pages/signup";
import AccountTabs from "../pages/myaccount";
import ProductDetailPage from "../pages/pdp";
import Cart from "../pages/cart";
import Checkout from "../pages/checkout";
import StoreLocator from "../pages/stores";
import Exchange from "../pages/exchange";
import AboutUs from "../pages/Aboutus";
import ScrollTop from "../components/scrollTop";
const Platform = lazy(() => import("../layout/layout"));

const RoutesOmApp = () => {
  return (
    <Router forceRefresh={false}>
      {/* <ScrollToTopOnMount /> */}
      <Suspense fallback={<LinearProgress />}>
        <ScrollTop />
        <Routes>
          <Route path="/" element={<Platform />}>
            <Route index element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<SignUp />} />
            <Route path="/my-account" element={<AccountTabs />} />
            <Route path="/items/filter" element={<ProductListingPage />} />
            <Route path="/item/:id" element={<ProductDetailPage />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/stores" element={<StoreLocator />} />
            <Route path="/exchange-policies" element={<Exchange />} />
            <Route path="/about-us" element={<AboutUs />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default RoutesOmApp;
