import React, { Component } from "react";
import { CarouselData } from "../data/carouselData";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Swipe from "react-easy-swipe";

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
      paused: false,
    };
  }

  componentDidMount() {
    setInterval(() => {
      if (this.state.paused === false) {
        let newSlide =
          this.state.currentSlide === CarouselData.length - 1
            ? 0
            : this.state.currentSlide + 1;
        this.setState({ currentSlide: newSlide });
      }
    }, 3000);
  }

  nextSlide = () => {
    let newSlide =
      this.state.currentSlide === CarouselData.length - 1
        ? 0
        : this.state.currentSlide + 1;
    this.setState({ currentSlide: newSlide });
  };

  prevSlide = () => {
    let newSlide =
      this.state.currentSlide === 0
        ? CarouselData.length - 1
        : this.state.currentSlide - 1;
    this.setState({ currentSlide: newSlide });
  };

  setCurrentSlide = (index) => {
    this.setState({ currentSlide: index });
  };

  render() {
    return (
      <div className="w-full mt-0 flex justify-center ">
        <div className="w-full h-auto overflow-hidden ">
          {/* Left Arrow */}
          {/* <AiOutlineLeft
            onClick={this.prevSlide}
            className="absolute left-4 top-1/2 transform -translate-y-1/2 text-3xl text-white cursor-pointer md:left-8 lg:left-16"
          /> */}

          <Swipe onSwipeLeft={this.nextSlide} onSwipeRight={this.prevSlide}>
            {CarouselData.map((slide, index) => (
              <div
                key={index}
                className={
                  index === this.state.currentSlide
                    ? "block w-full h-[200px] sm:h-[300px] md:h-[400px] lg:h-[500px] xl:h-[600px]"
                    : "hidden"
                }
                onMouseEnter={() => this.setState({ paused: true })}
                onMouseLeave={() => this.setState({ paused: false })}
              >
                {slide.type === "video" ? (
                  <video
                    src={slide.src}
                    autoPlay
                    muted
                    loop
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <img
                    src={slide.src}
                    alt="This is a carousel slide"
                    className="w-full h-full object-cover"
                  />
                )}
              </div>
            ))}
          </Swipe>

          {/* Right Arrow
          <AiOutlineRight
            onClick={this.nextSlide}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-3xl text-white cursor-pointer md:right-8 lg:right-16"
          /> */}
        </div>
      </div>
    );
  }
}

export default Carousel;
