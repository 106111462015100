import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const OrderAPI = {
  submit: async function (formData, cancel = false) {
    const response = await api.request({
      url: `/api/orders`,
      method: "POST",
      data: formData,
      signal: cancel
        ? cancelApiObject[this.submit.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/orders/verification/${id}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(OrderAPI);
