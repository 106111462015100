import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const LogsAPI = {
  send: async function (formData, cancel = false) {
    console.log(formData);
    const response = await api.request({
      url: `/api/front/logs`,
      method: "POST",
      data: formData,
      signal: cancel
        ? cancelApiObject[this.send.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(LogsAPI);
