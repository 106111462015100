import React from "react";
import { LogsAPI } from "../../api/LogsAPI";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
    const sessionData = this.getSessionData();
    const formData = {
      error: error.toString(),
      errorInfo: errorInfo,
      sessionData: sessionData,
    };
    this.logErrorToBackend(formData);
  }

  static logCustomError(customErrorMessage) {
    const sessionData = ErrorBoundary.getSessionDataStatic();
    const formData = {
      error: customErrorMessage,
      sessionData: sessionData,
    };
    ErrorBoundary.logErrorToBackendStatic(formData);
  }

  static getSessionDataStatic() {
    return {
      url: window.location.href,
      userAgent: navigator.userAgent,
      localStorageData: { ...localStorage },
      sessionStorageData: { ...sessionStorage },
    };
  }

  static logErrorToBackendStatic(formData) {
    try {
      LogsAPI.send(formData);
    } catch (err) {
      console.error("Failed to send logs:", err);
    }
  }

  getSessionData() {
    return ErrorBoundary.getSessionDataStatic();
  }

  logErrorToBackend = (formData) => {
    ErrorBoundary.logErrorToBackendStatic(formData);
  };

  render() {
    if (this.state.hasError) {
      return <></>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
