import React, { useState, useContext } from "react";
import ShoppingCartProductContext from "../components/shoppingCart/ShoppingCartProductContext";
import { sumItems } from "../components/shoppingCart/ShoppingCartReducer";
import { API_URL_IMAGE_RESIZED } from "../api/URL/constants";
import CustomerDetailsForm from "../components/CustomerDetailsForm";
import { OrderAPI } from "../api/OrderAPI";
import DialogOrderDone from "../components/DialogOrderDone";
import DialogOrderCancelled from "../components/DialogOrderCancelled";
import ReactPixel from "react-facebook-pixel";
const Checkout = () => {
  const { cartItems, clearCart } = useContext(ShoppingCartProductContext);
  const { itemCount, total } = sumItems(cartItems);
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [showOrderDialogDone, setShowOrderDialogDone] = useState(false);
  const [showOrderDialogCancelled, setShowOrderDialogCancelled] =
    useState(false);
  const freeShipping = 0.0;
  const shippingCost = 4.0;
  const totalCart = parseFloat(total);
  const totalOrderAmount = totalCart + shippingCost;
  const totalOrderAmountFreeShipping = totalCart + freeShipping;
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const submitForm = async (formData) => {
    const items = cartItems.map((item) => {
      return { id: item.id, quantity: item.quantity };
    });
    formData.items = items;
    setLoading(true);
    try {
      const response = await OrderAPI.submit(formData);
      setLoading(false);
      setOrderId(response.orderId);
      setShowOrderDialogDone(true);
      ReactPixel.track("Purchase", {
        value: (parseFloat(total) + 0).toFixed(2),
        currency: "$",
      });
      clearCart();
    } catch (error) {
      setLoading(false);
      setShowOrderDialogCancelled(true);
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div className="lg:mx-32">
          <div className="container mx-auto p-4 lg:flex lg:space-x-8">
            {/* Left Side: Recap of Cart Items */}
            <div className="w-full lg:w-2/3">
              <h2 className="text-lg font-semibold mb-4">Order Summary</h2>
              <div className="space-y-4">
                {cartItems.length > 0 ? (
                  <>
                    {cartItems.map((item) => (
                      <div key={item.id} className="border-b pb-4">
                        <div className="flex items-center justify-between">
                          <img
                            className="w-20 h-20 object-cover"
                            src={API_URL_IMAGE_RESIZED + item.images[0].name}
                            alt={item.name}
                          />
                          <div className="flex-1 ml-4">
                            {item.new && (
                              <span className="text-xs bg-gray-200 text-gray-700 px-2 py-1">
                                NEW-IN
                              </span>
                            )}
                            <h3 className="text-sm font-semibold">
                              {item.name}
                            </h3>
                            <p className="text-sm">Size: {item.size.name}</p>
                            <div
                              className="w-6 h-6 rounded-full flex items-center justify-center"
                              style={{
                                backgroundColor: `#${item.color}`,
                                opacity: "75%",
                                borderWidth: 1,
                                borderColor: "black",
                              }}
                            ></div>
                          </div>
                          <div className="flex-3 ml-4">
                            {item.discount ? (
                              <>
                                <p className="font-semibold font-un line-through opacity-50">
                                  ${item.price}
                                </p>

                                <p className="font-semibold font-un">
                                  $
                                  {(
                                    ((100 - item.discount) / 100) *
                                    item.price
                                  ).toFixed(2)}
                                </p>
                              </>
                            ) : (
                              <p className="font-semibold">${item.price}</p>
                            )}

                            <p className="font-extralight text-right">
                              X{item.quantity}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div>
                    <p className="text-center opacity-50">Your cart is empty</p>
                  </div>
                )}
              </div>

              {/* Shipping Method */}
              <div className="mt-8 border p-4 rounded-lg bg-gray-50">
                <h3 className="font-semibold mb-4">Shipping Method</h3>
                <div className="flex items-center">
                  <img
                    className="w-16 h-16"
                    src="https://upload.wikimedia.org/wikipedia/commons/e/ee/Aramex_logo.svg"
                    alt="Shipping method"
                  />
                  <div className="ml-4">
                    <h4 className="font-semibold">Standard Shipping</h4>
                    <p className="text-sm">
                      Delivered within 5-10 business days
                    </p>
                    <p className="font-semibold">
                      $
                      {totalCart > 100
                        ? freeShipping.toFixed(2)
                        : shippingCost.toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
              {cartItems.length > 0 ? (
                <div className="mt-4 p-4 border-t border-gray-200 text-right">
                  <h3 className="text-lg font-semibold">Total Order Amount</h3>
                  <p className="text-2xl font-bold text-black">
                    $
                    {totalCart > 100
                      ? totalOrderAmountFreeShipping.toFixed(2)
                      : totalOrderAmount.toFixed(2)}
                  </p>
                </div>
              ) : null}
            </div>

            <CustomerDetailsForm
              FormCustomerDetails={submitForm}
              loading={() => setLoading(true)}
              scrollTo={scrollToTop}
            />
          </div>

          {/* <div className="mt-4 p-4 border-t border-gray-200 text-right">
            <h3 className="text-lg font-semibold">Total Order Amount</h3>
            <p className="text-2xl font-bold text-black">
              ${totalOrderAmount.toFixed(2)}
            </p>
          </div> */}
        </div>
      )}

      {/* Dialogs */}
      {showOrderDialogDone && (
        <DialogOrderDone
          orderId={orderId}
          onClose={() => setShowOrderDialogDone(false)}
        />
      )}
      {showOrderDialogCancelled && (
        <DialogOrderCancelled
          onClose={() => setShowOrderDialogCancelled(false)}
        />
      )}
    </>
  );
};

export default Checkout;
