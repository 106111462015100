export const statesData = {
  regions: [
    { value: "Aakkar", label: "Aakkar" },
    { value: "Aaley", label: "Aaley" },
    { value: "Baabda", label: "Baabda" },
    { value: "Baalbek", label: "Baalbek" },
    { value: "Batroun", label: "Batroun" },
    { value: "Bcharreh", label: "Bcharreh" },
    { value: "Bent Jbeil", label: "Bent Jbeil" },
    { value: "Beqaa Ouest", label: "Beqaa Ouest" },
    { value: "Beyrouth", label: "Beyrouth" },
    { value: "Chouf", label: "Chouf" },
    { value: "Hasbaiya", label: "Hasbaiya" },
    { value: "Hermel", label: "Hermel" },
    { value: "Jbeil", label: "Jbeil" },
    { value: "Jezzine", label: "Jezzine" },
    { value: "Kesrouane", label: "Kesrouane" },
    { value: "Koura", label: "Koura" },
    { value: "Marjaayoun", label: "Marjaayoun" },
    { value: "Matn", label: "Matn" },
    { value: "Minieh-Danniyeh", label: "Minieh-Danniyeh" },
    { value: "Nabatiyeh", label: "Nabatiyeh" },
    { value: "Rachaiya", label: "Rachaiya" },
    { value: "Saida", label: "Saida" },
    { value: "Sour", label: "Sour" },
    { value: "Tripoli", label: "Tripoli" },
    { value: "Zahleh", label: "Zahleh" },
    { value: "Zgharta", label: "Zgharta" },
  ],
};
