import React from "react";
import { Link } from "react-router-dom";

const SectionWithCTA = ({ imageSrc, buttons }) => {
  return (
    <div className="relative w-full mt-7 h-96 md:h-[400px] lg:h-[600px] xl:h-[700px]">
      {/* Background Image */}
      <img
        src={imageSrc}
        alt="Section background"
        className="w-full h-full object-cover"
      />

      {/* CTA Buttons */}
      <div className="absolute bottom-8 inset-x-0 flex flex-col items-center space-y-4">
        {/* Render Buttons Dynamically */}
        {buttons.map((row, rowIndex) => (
          <div key={rowIndex} className="flex space-x-4">
            {row.map(({ name, route }, buttonIndex) => (
              <Link
                key={buttonIndex}
                to={route}
                className="w-32 px-2 py-3 md:w-48 lg:w-64 bg-white text-black font-semibold rounded border border-black transition text-center"
              >
                {name}
              </Link>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionWithCTA;
