export const CarouselData = [
  {
    type: "video",
    src: "https://cdn-media.originalmarines.com/pub/media/video/FW24_Slider_Desktop_05112024.mp4",
  },
  // {
  //   type: "image",
  //   src: "https://genuins.com/cdn/shop/files/BANNER_WEB_PRINCIPAL.jpg?v=1711376271&width=1600",
  // },
];
