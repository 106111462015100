import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import SizeGuideModal from "../components/sizeGuide"; // Import the modal component
import { useParams, useNavigate } from "react-router-dom";
import { ProductAPI } from "../api/ProductAPI";
import { API_URL_IMAGE_ITEM } from "../api/URL/constants";
import { API_URL_IMAGE_RESIZED } from "../api/URL/constants";
import ShoppingCartProductContext from "../components/shoppingCart/ShoppingCartProductContext";
const ProductDetailPage = () => {
  const [selectedImage, setSelectedImage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal visibility
  const { addToCart, cartItems, increase } = useContext(
    ShoppingCartProductContext
  );
  const [showNotification, setShowNotification] = useState(false); // State for notification
  const isOnSale = true; // Set true if the product is on sale
  const discountPercentage = 70; // Example discount for sale label
  const navigate = useNavigate();
  const { id } = useParams();
  const [product, setProduct] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState({
    color: "",
    price: "",
    size: "",
    images: [],
  });
  const [loading, setLoading] = useState(true);
  const isInCart = (product) => {
    return !!cartItems.find((item) => item.id === product.id);
  };
  const getQuantity = (product) => {
    const foundItem = cartItems.find((item) => item.id === product.id);
    return foundItem ? foundItem.quantity : 0;
  };
  // Function to handle image navigation
  const handleNextImage = () => {
    setSelectedImage((prevImage) =>
      prevImage === selectedVariant.images.length - 1 ? 0 : prevImage + 1
    );
  };
  const selectColor = (variantColor) => {
    if (variantColor === selectedVariant.color) {
      return;
    }
    const newVariant = getFirstSizeAndImagesByColor(variantColor);
    setSelectedVariant({
      color: variantColor,
      price: newVariant.firstSize.price,
      quantity_in_stock: newVariant.firstSize.quantity,
      id: newVariant.firstSize.id,
      size: newVariant.firstSize.size,
      images: newVariant.images,
    });
    setSelectedImage(0);
  };
  const sortSizes = (sizes) => {
    return sizes.sort((a, b) => {
      const sizeA = a.size.name;
      const sizeB = b.size.name;

      const getNumericPart = (size) => parseInt(size, 10);
      const hasNumber = (size) => /^\d+$/.test(size);
      const hasM = (size) => size.endsWith("M");
      const hasA = (size) => size.endsWith("A");

      if (hasNumber(sizeA) && hasNumber(sizeB)) {
        return getNumericPart(sizeA) - getNumericPart(sizeB);
      }

      if (hasNumber(sizeA)) return -1;
      if (hasNumber(sizeB)) return 1;

      if (hasM(sizeA) && hasM(sizeB)) {
        return getNumericPart(sizeA) - getNumericPart(sizeB);
      }

      if (hasM(sizeA)) return -1;
      if (hasM(sizeB)) return 1;

      if (hasA(sizeA) && hasA(sizeB)) {
        return getNumericPart(sizeA) - getNumericPart(sizeB);
      }

      if (hasA(sizeA)) return -1;
      if (hasA(sizeB)) return 1;

      return 0;
    });
  };
  const handleAddToCart = () => {
    if (!isInStock()) {
      alert("This product is out of stock!");
      return;
    }
    if (isInCart(selectedVariant)) {
      if (selectedVariant.quantity_in_stock > getQuantity(selectedVariant)) {
        //setShowNotification(true);
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 3000);
        increase(selectedVariant);
      }
    } else {
      // setShowNotification(true);
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
      selectedVariant.name = product.name;
      selectedVariant.discount = product.discount;
      addToCart(selectedVariant);
    }
  };
  const selectSize = (size) => {
    setSelectedVariant((prevState) => ({
      ...prevState,
      size: size.size,
      id: size.id,
      price: size.price,
      quantity_in_stock: size.quantity,
      discount: size.discount ? size.discount : 0,
    }));
  };
  const getFirstSizeAndImagesByColor = (color) => {
    const variant = product.variants.find((variant) => variant.color === color);

    if (variant) {
      return {
        firstSize: variant.sizes.length > 0 ? variant.sizes[0] : null,
        images: variant.images,
      };
    }

    return null;
  };
  const handlePrevImage = () => {
    setSelectedImage((prevImage) =>
      prevImage === 0 ? selectedVariant.images.length - 1 : prevImage - 1
    );
  };
  const isInStock = () => {
    return selectedVariant.quantity_in_stock > 0;
  };
  const getAllSizesByColor = () => {
    const variant = product.variants.find(
      (variant) => variant.color === selectedVariant.color
    );

    if (variant) {
      return variant.sizes;
    }

    return [];
  };
  useEffect(() => {
    apiCall();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const apiCall = async () => {
    if (id) {
      try {
        await ProductAPI.get(id).then((productFetchingData) => {
          productFetchingData.variants.forEach((variant) => {
            variant.sizes = sortSizes(variant.sizes);
          });
          setProduct(productFetchingData);
          setSelectedVariant({
            id: productFetchingData.variants[0].sizes[0].id,
            color: productFetchingData.variants[0].color,
            size: productFetchingData.variants[0].sizes[0].size,
            price: productFetchingData.variants[0].sizes[0].price,
            quantity_in_stock:
              productFetchingData.variants[0].sizes[0].quantity,
            images: productFetchingData.variants[0].images,
          });

          setLoading(false);
          scrollToTop();
        });
      } catch {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div className="container mx-auto p-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
          {/* Notification */}
          {showNotification && (
            <div className="fixed top-4 mt-28 right-4 bg-green-500 text-white px-4 py-2 rounded shadow">
              Item added to cart successfully!
            </div>
          )}

          <div className="flex flex-col md:flex-row items-center md:items-start">
            {/* Main Image */}
            <div className="relative w-full lg:max-w-lg">
              {product.discount && (
                <div className="absolute top-2 right-2 bg-red-500 text-white text-xs rounded px-2 py-1">
                  {product.discount}% OFF
                </div>
              )}
              <img
                src={
                  selectedVariant.images[selectedImage] &&
                  API_URL_IMAGE_RESIZED +
                    selectedVariant.images[selectedImage].name
                }
                alt="Product"
                className="w-full h-auto object-cover"
              />

              {/* Previous and Next buttons */}
              <button
                className="absolute left-2 top-1/2 transform -translate-y-1/2"
                onClick={handlePrevImage}
                style={{
                  fontSize: "30px",
                  color: "#222",
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "38px",
                  height: "38px",
                  border: "2px solid #222",
                  borderRadius: "50%",
                }}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>

              <button
                className="absolute right-2 top-1/2 transform -translate-y-1/2"
                onClick={handleNextImage}
                style={{
                  fontSize: "30px",
                  color: "#222",
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "38px",
                  height: "38px",
                  border: "2px solid #222",
                  borderRadius: "50%",
                }}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>

            {/* Image Thumbnails */}
            <div className="mt-4 flex flex-row justify-center space-x-2 md:mt-0 md:flex-col md:space-y-2 md:space-x-0 md:mr-4 md:order-first">
              {selectedVariant &&
                selectedVariant.images.map((image, index) => (
                  <img
                    key={index}
                    src={API_URL_IMAGE_RESIZED + image.name}
                    alt={`Thumbnail ${index + 1}`}
                    onClick={() => setSelectedImage(index)}
                    className={`w-16 h-16 object-cover cursor-pointer ${
                      selectedImage === index ? "border-2 border-gray-500" : ""
                    }`}
                  />
                ))}
            </div>
          </div>

          {/* Right section: Product Details */}
          <div className="p-4 space-y-4">
            {/* Product Name */}
            <h1 className="text-2xl font-bold">{product.name}</h1>
            <p className="text-sm font-bold">{product.barcode}</p>

            {/* Price */}
            <p className="text-xl">
              {product.discount ? (
                <>
                  <span className="text-red-600 font-bold line-through">
                    ${selectedVariant.price}
                  </span>{" "}
                  <span className="text-green-600 font-bold">
                    $
                    {(
                      ((100 - product.discount) / 100) *
                      selectedVariant.price
                    ).toFixed(2)}
                  </span>
                </>
              ) : (
                <span>${selectedVariant.price}</span>
              )}
            </p>

            {/* Color Options */}
            <div>
              <span className="font-bold">Color:</span>
              <div className="flex space-x-2 mt-2">
                {product.variants &&
                  product.variants.map((variant, index) => (
                    <div
                      style={{
                        backgroundColor: "#" + variant.color,
                        opacity: "75%",
                      }}
                      // className="w-6 h-6 rounded-full border-2 border-black ring ring-offset-1" //ici faut ajouter une condition si le selected variant id == le current vairaint id mettre le ring et aussi ajouter onClick pour selectionner le variant
                      className={`w-6 h-6 rounded-full border-2 border-black cursor-pointer ${
                        variant.color === selectedVariant.color
                          ? "border-black ring ring-offset-1"
                          : ""
                      }`}
                      onClick={() => selectColor(variant.color)}
                    ></div>
                  ))}
                {/* <button className="w-6 h-6 bg-purple-500 rounded-full"></button>
            <button className="w-6 h-6 bg-blue-500 rounded-full"></button> */}
              </div>
            </div>

            {/* Size Options */}
            <div>
              <span className="font-bold">Size:</span>
              <div className="grid grid-cols-3 gap-2 mt-2">
                {product.variants &&
                  selectedVariant &&
                  getAllSizesByColor()
                    .sort((a, b) => {
                      if (a.quantity > 0 && b.quantity <= 0) return -1;
                      if (a.quantity <= 0 && b.quantity > 0) return 1;

                      const getRangeStart = (size) => {
                        const match = size.size.name.match(/^\d+/);
                        return match ? parseInt(match[0], 10) : 0;
                      };

                      return getRangeStart(a) - getRangeStart(b);
                    })
                    .map((size) => (
                      <div
                        key={size.size.id}
                        className={`p-2 text-center ${
                          size.quantity < 1
                            ? "cursor-not-allowed text-gray-600 text-opacity-70 border-2"
                            : size.size.id === selectedVariant.size.id
                            ? "cursor-pointer border-2 border-blue-600"
                            : "cursor-pointer border"
                        }`}
                        onClick={() => size.quantity > 0 && selectSize(size)}
                      >
                        {size.size.name}
                      </div>
                    ))}
              </div>
            </div>

            {/* Size Guide Button */}
            <button
              onClick={() => setIsModalOpen(true)} // Open the modal when clicked
              className="text-blue-600"
            >
              Size Guide
            </button>

            {/* Shipping Info */}
            <div>
              <p className="text-blue-700 font-medium">
                Shipping in 3-5 working days
              </p>
            </div>

            {/* Add to Cart Button */}
            <button
              className={`w-full py-2 mt-4 text-white ${
                isInStock()
                  ? "bg-blue-800 hover:bg-blue-600"
                  : "bg-gray-400 cursor-not-allowed"
              }`}
              onClick={handleAddToCart}
              disabled={!isInStock()}
            >
              {isInStock() ? "Add to Cart" : "Out of Stock"}
            </button>

            {/* Product Details */}
            {product.description ? (
              <div className="mt-4">
                <h2 className="font-bold">Product Details</h2>
                <p>{product.description}</p>
              </div>
            ) : null}
          </div>

          {/* Size Guide Modal */}
          <SizeGuideModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        </div>
      )}
    </>
  );
};

export default ProductDetailPage;
