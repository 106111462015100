export const filterData = {
  sizes: [
    { id: "0a8572a9-a818-4d94-a3c5-460ebfa39028", name: "0-1 months" },
    { id: "69692b29-6523-4c16-bc97-bd2004086dcc", name: "1-3  months" },
    { id: "686fe17f-8de5-409e-ba0a-084e99714e98", name: "3-6 months" },
    { id: "0c6222dc-508b-44a3-8502-d73c0d7910ad", name: "6-9 months" },
    { id: "4d5f51fb-f776-46f1-b35b-526ce9f1a0f0", name: "9-12 months" },
    { id: "90838450-cef0-47a5-a223-a706dbe4fcfd", name: "12-18 months" },
    { id: "4272231c-445d-4600-a5e1-3c2f06ff6845", name: "18-24 months" },
    { id: "aa32f922-5296-448f-a823-9e1915e43f4a", name: "30-36 months" },
    { id: "a6b15d8a-1b8e-4dc0-b116-08fafc0321fa", name: "3-4 years" },
    { id: "12d3e334-4962-4f96-9f6d-6b1948d9c23f", name: "4-5 years" },
    { id: "a60161ec-a4e1-4188-a47c-678a3de08195", name: "5-6 years" },
    { id: "4f1341cf-da86-4771-b7a7-5a985f5ed3a1", name: "7-8 years" },
    { id: "e087fe3e-d6f6-432c-9019-56ec9ddb0dfc", name: "9-10 years" },
    { id: "15e4e512-27d5-494c-869e-4579672f837d", name: "11-12 years" },
    { id: "990e3cff-ce24-4707-9f5c-3e4519160833", name: "13-14 years" },
    { id: "3b0b84bb-81f1-4428-a483-4f8bca3dcc35", name: "18" },
    { id: "19f86b19-7d86-4dd0-a728-0db7f60e0b8c", name: "20" },
    { id: "b0c1b618-8b9b-4649-8e5d-dd7a3bcfe4aa", name: "21" },
    { id: "36c3afec-b922-48f3-95a9-c2589b674144", name: "22" },
    { id: "87abd393-18e9-437f-ae5a-71ed363c77eb", name: "23" },
    { id: "111ae075-516d-4cbe-b0bb-b811ecb3cbe9", name: "24" },
    { id: "fa257e4e-a2c1-40fd-aabf-5dc6a51c4f96", name: "25" },
    { id: "409af2f2-d3a0-4244-ac6c-47c8da181d5b", name: "26" },
    { id: "8e793580-c3f5-46a8-8553-3e9a18ab0143", name: "27" },
    { id: "2ea2d8b8-e367-4960-9493-a2a0837b14b2", name: "28" },
    { id: "23d64760-7ff5-4de8-a70c-f3bbb81325c3", name: "29" },
    { id: "c5c27f47-f838-4e6e-ae6a-8b7bb3155bd6", name: "30" },
    { id: "a50de1e5-b19b-48ff-8759-e2a3d4608c68", name: "31" },
    { id: "29a63553-2ba1-4a83-8289-54cde979e32d", name: "32" },
    { id: "d8f42c42-3e94-498f-8140-a08ab977b617", name: "33" },
    { id: "5d6c7089-2433-415f-aa7c-a3af529dc0b9", name: "34" },
    { id: "3a64977a-cdb3-45ca-897e-93af5762a945", name: "35" },
    { id: "72c4b296-d349-4166-89fc-36b634cb518e", name: "36" },
    { id: "e8395afb-3feb-4c1c-aaf4-984eedcbb686", name: "37" },
    { id: "7c0161cd-d442-4853-a969-04e1f1f3b982", name: "39" },
    { id: "c76b4d84-4322-4638-a849-fa6a04948b4a", name: "41" },
    { id: "9d1cfded-abf3-4231-bbd8-e2ba5bcbe63f", name: "UNI" },
  ],
  colors: [
    {
      id: "05a688f2-5bf1-40f5-bafd-663ae54aff82",
      name: "CEL",
      value: "3cbfea",
    },
    {
      id: "090e8b52-46ba-434b-b97d-df34bcf28074",
      name: "BRSO",
      value: "039000",
    },
    {
      id: "0b530be7-0674-49fc-8fca-ad4599a35bc6",
      name: "NAV",
      value: "2713a9",
    },
    {
      id: "17414db3-b9e3-4e41-befa-ffb1d56dd1ed",
      name: "BLU",
      value: "2626ff",
    },
    {
      id: "25d48ee8-319f-46bd-b6e9-c2f3c0b4cb84",
      name: "GLD",
      value: "eccc2d",
    },
    {
      id: "2a89bf57-bb60-4adb-8931-4c52220a176b",
      name: "COR",
      value: "000000",
    },
    {
      id: "2d7f87a3-9f86-4f81-be76-f30f90935acd",
      name: "BRZ",
      value: "220000",
    },
    {
      id: "3950bf62-f978-4f0a-aa57-ae887e5a63f0",
      name: "RSO",
      value: "fd0f1b",
    },
    {
      id: "3c38b24d-72a9-452e-b24e-a69e1e175517",
      name: "ROY",
      value: "68d6ff",
    },
    {
      id: "4fff0964-d6fa-4536-96b2-fc12d7344fa7",
      name: "LIL",
      value: "e997ec",
    },
    {
      id: "55c05585-faab-4fd9-a3b7-9a4b5ef1eedd",
      name: "SIL",
      value: "350000",
    },
    {
      id: "5eb9e445-5640-42f1-8ebc-1b57a1f808f9",
      name: "ARA",
      value: "ffb959",
    },
    {
      id: "62b44e4a-05ac-46ca-90a6-413dda0b4447",
      name: "PAN",
      value: "ffffff",
    },
    {
      id: "67a4a29b-2553-4f38-9cee-0c4362dab0cb",
      name: "VER",
      value: "11e833",
    },
    {
      id: "69982ed5-df7a-4c68-8625-4e926b338f10",
      name: "BBLU",
      value: "475000",
    },
    {
      id: "6f12b2bb-75a2-4c13-b217-92c76e4b8511",
      name: "BEI",
      value: "d9bd80",
    },
    {
      id: "8a53e3e1-4d7d-4ca8-a431-a8a4cc5721f7",
      name: "GRI",
      value: "99999a",
    },
    {
      id: "91ee10a5-2733-4d3e-b4bc-02b5953213b1",
      name: "EMD",
      value: "990000",
    },
    {
      id: "96a19c36-3987-4424-9d2e-700e7415c61c",
      name: "CAM",
      value: "f3a31d",
    },
    {
      id: "982a9282-124c-468e-9fe1-0c99f2a99b4a",
      name: "ROS",
      value: "fc3ca5",
    },
    { id: "9aca6172-b908-4690-9f55-c6ae0db869d2", name: "GA", value: "989898" },
    {
      id: "acb795bd-81aa-47e4-9f1d-16a788dfe58a",
      name: "FUX",
      value: "ff08f5",
    },
    {
      id: "ad36f2e9-e6f6-4c3c-8f50-83c876f60208",
      name: "GIA",
      value: "faed76",
    },
    {
      id: "b16e828f-4a83-40bb-8130-61614c3c4e84",
      name: "VIO",
      value: "720a4f",
    },
    { id: "bbb8c71c-93d2-45e4-ad50-9e690e738293", name: "V2", value: "000020" },
    { id: "c48af4c6-460a-40a0-b209-85d14abe1d74", name: "V3", value: "000004" },
    {
      id: "d0d3a8e7-df16-44a2-b852-5cda3ad4500c",
      name: "KAK",
      value: "011000",
    },
    {
      id: "d177a377-7d1b-47ad-8c99-8a60e257aa92",
      name: "MAR",
      value: "895605",
    },
    { id: "d8052905-5dcb-4bd8-a577-ce6fe7da6eaf", name: "BR", value: "111000" },
    {
      id: "d8d712e1-2ee4-4c1b-a836-79ca2f998c97",
      name: "BIS",
      value: "003300",
    },
    {
      id: "db1ba05f-c7b9-4791-9555-bf1b20aee120",
      name: "DEN",
      value: "1313c8",
    },
    {
      id: "f12bf032-84b5-45c4-bc6a-05184de72cfc",
      name: "AZU",
      value: "63cffa",
    },
    {
      id: "f3b170cf-51b4-47e5-affc-46cc17e50113",
      name: "BIA",
      value: "efeded",
    },
    {
      id: "fa00ae12-eb6c-435c-9110-4845bd4f52f0",
      name: "NER",
      value: "101010",
    },
    { id: "fb94dd89-7e4d-4e2e-b4e2-806d6aea295c", name: "V1", value: "000444" },
  ],
  discounts: [
    { id: "01c5a56d-d205-4967-9e55-bd392b9d43c0", name: "30%" },
    { id: "eb68d119-7070-4fa8-9fdd-79cd4d003e2d", name: "50%" },
    { id: "c28e8299-49c3-4887-a40d-6f7ddada339c", name: "70%" },
  ],
};
