import React, { useState } from "react";

const AboutUs = () => {
  const [activeTab, setActiveTab] = useState("History");

  return (
    <div className="bg-white min-h-screen py-10 px-4 md:px-10 lg:px-20">
      <h1 className="text-2xl text-red-500 md:text-4xl font-bold text-center mb-8">
        About Us
      </h1>
      <div className="border-b border-gray-200 mb-6">
        <div className="flex justify-center space-x-6">
          {["History", "Purpose", "Values"].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`text-lg font-medium py-2 px-4 ${
                activeTab === tab
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-600 hover:text-blue-500"
              }`}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-md">
        {activeTab === "History" && (
          <div>
            <h2 className="text-xl font-semibold mb-4">
              Born looking at the future
            </h2>
            <p className="text-gray-700 mb-6">
              We started from a simple white T-shirt. We have explored new
              styles and reached new continents. During the years, so many kids
              grew up wearing Original Marines, and now they are choosing our
              clothes for their own kids. Their history is our history.
            </p>
            <ul className="space-y-4">
              {[
                {
                  year: "1983",
                  event:
                    "The white T-shirt is the brand’s iconic product. From ’83 to ‘92 about 25 million garments sold.",
                },
                {
                  year: "1986",
                  event:
                    "Distribution in the Middle East and expansion of the product range with a complete line of casual and sportswear for the entire family.",
                },
                {
                  year: "1993",
                  event:
                    "From wholesale to monobrand. In a ten-year period, Original Marines establishes about 400 points of sale between franchised and owned stores in Italy and abroad.",
                },
                {
                  year: "1998",
                  event:
                    "Signed a multi-year licensing contract with Warner Bros, for the use of Looney Tunes, and it is still in place.",
                },
                {
                  year: "2007",
                  event:
                    "Original Marines gains leadership in the medium kidswear market with 9% market share.",
                },
                {
                  year: "2010",
                  event:
                    "The company sellout reaches 20 million garments in a single year.",
                },
                {
                  year: "2011",
                  event:
                    "The group’s revenue exceeds 200 million. Original Marines receives the Quality Products Awards 2011 in the 'Integrated Communication and Marketing' category.",
                },
                {
                  year: "2017",
                  event:
                    "Growth of the network: 505 franchised and 65 owned stores in Italy and 140 between Europe, Asia, and Africa. Launch of the Newborn line for the 0-18-month range.",
                },
                {
                  year: "2018",
                  event:
                    "Original Marines S.p.A. absorbs TRADER s.r.l to develop synergies and optimize the Group’s network sales.",
                },
                {
                  year: "2019",
                  event:
                    "The company name changes from Imap Export S.p.A. to Original Marines, strengthening corporate identity.",
                },
                {
                  year: "2023",
                  event:
                    "The appointment of a General Director strengthens the organisational setup, favoring the achievement of the next challenging growth objectives.",
                },
              ].map(({ year, event }) => (
                <li key={year} className="flex space-x-4">
                  <span className="font-bold text-blue-500">{year}</span>
                  <span className="text-gray-700">{event}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
        {activeTab === "Purpose" && (
          <div>
            <h2 className="text-xl font-semibold mb-4">Our Purpose</h2>
            <p className="text-gray-700">
              Every choice we make, from the designs of our collections to the
              selection of our fabrics, is driven by a singular vision: to
              elevate the future for our children. Day by day, this future
              unfolds right before our eyes, crafted by every decision we
              make—and not just in style. Our commitment stretches beyond
              garments to the world we will leave our children. That’s why we
              weave social and environmental sustainability into every aspect of
              our process, from conception through to delivery, ensuring that
              our impact on the lives of the young is both positive and
              enduring.
            </p>
          </div>
        )}
        {activeTab === "Values" && (
          <div>
            <h2 className="text-xl font-semibold mb-4">Our Values</h2>
            <p className="text-gray-700">
              The core values of our company stem from a collective identity,
              passionately embraced and deeply felt by each member of our
              Original Marines family. They are the one thing that inspires us
              and pushes us to do our very best to clothe the future of our
              children.
            </p>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>Passion</li>
              <li>Determination</li>
              <li>Excellence</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default AboutUs;
