import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactPixel from "react-facebook-pixel";
const root = ReactDOM.createRoot(document.getElementById("root"));

const options = {
  autoConfig: true,
  debug: false,
};

ReactPixel.init("275085961588400", options);

ReactPixel.pageView();

root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
