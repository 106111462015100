import axios from "axios";
import { API_URL } from "../URL/constants";
import { setupCache } from "axios-cache-interceptor";

const cache = setupCache(axios, {
  ttl: 15 * 60 * 1000, // 15 minutes
});

export const api = cache.create({
  withCredentials: false,
  baseURL: API_URL,
  headers: { "Content-Type": "application/json", Accept: "application/json" },
  xsrfHeaderName: "X-XSRF-TOKEN",
});

const errorHandler = (error) => {
  const statusCode = error.response?.status;
  return Promise.reject(error);
};

api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});
